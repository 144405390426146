import createReducer from "../createReducer";
import { getToken } from "./user";
import qs from "query-string";

import { getRawMaterialsForSelectedCategory } from "./rawMaterials";
//import { removeFalsy } from "../utils";

import { MODAL_PAGE_SIZE, PAGE_SIZE } from "../constants";

export const SET_ONLY_MINE = "filterSearch.SET_ONLY_MINE";
export const SET_ORDERING = "filterSearch.SET_ORDERING";

export const OPEN_RAW_MATERIALS_MODAL = "filterSearch.OPEN_RAW_MATERIALS_MODAL";
export const CLOSE_RAW_MATERIALS_MODAL = "filterSearch.CLOSE_RAW_MATERIALS_MODAL";

export const MODAL_GET_RAW_MATERIALS_REQUEST = "filterSearch.MODAL_GET_RAW_MATERIALS_REQUEST";
export const MODAL_GET_RAW_MATERIALS_SUCCESS = "filterSearch.MODAL_GET_RAW_MATERIALS_SUCCESS";
export const MODAL_GET_RAW_MATERIALS_FAILURE = "filterSearch.MODAL_GET_RAW_MATERIALS_FAILURE";

export const MODAL_GET_RAW_MATERIAL_NAMES_REQUEST = "filterSearch.MODAL_GET_RAW_MATERIAL_NAMES_REQUEST";
export const MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS = "filterSearch.MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS";
export const MODAL_GET_RAW_MATERIAL_NAMES_FAILURE = "filterSearch.MODAL_GET_RAW_MATERIAL_NAMES_FAILURE";

export const MODAL_GET_CAS_REQUEST = "filterSearch.MODAL_GET_CAS_REQUEST";
export const MODAL_GET_CAS_SUCCESS = "filterSearch.MODAL_GET_CAS_SUCCESS";
export const MODAL_GET_CAS_FAILURE = "filterSearch.MODAL_GET_CAS_FAILURE";

export const CLEAR_RAW_MATERIALS_MODAL = "filterSearch.CLEAR_RAW_MATERIALS_MODAL";

export const CLEAR_CONCENTRATION_FILTERS = "filterSearch.CLEAR_CONCENTRATION_FILTERS";

export const CLEAR_SEARCH_FILTERS = "filterSearch.CLEAR_SEARCH_FILTERS";

import { GET_RAW_MATERIALS_SUCCESS } from "./rawMaterials";

export const clearRawMaterialsModal = () => ({
  type: CLEAR_RAW_MATERIALS_MODAL,
});

// Add this with your other action creators
export const _clearSearchFilters = () => (dispatch) => {
  // Dispatch the clear filters action
  dispatch({ type: CLEAR_SEARCH_FILTERS });

  // IMPORTANT: This extra dispatch explicitly clears the family_min_qty
  dispatch({
    type: CLEAR_CONCENTRATION_FILTERS,
  });

  // Also dispatch a filterRawMaterials action with empty filters
  // to refresh the data immediately
  dispatch(
    filterRawMaterials({
      page: 1,
      page_size: 20, // Use your default page size
      only_gcms: true,
    })
  );
};

// In filterSearch.js
export const __clearSearchFilters = () => (dispatch) => {
  // First, dispatch the basic clear action
  dispatch({ type: CLEAR_SEARCH_FILTERS });

  // Then, explicitly clear concentration filters
  dispatch({ type: CLEAR_CONCENTRATION_FILTERS });

  // Finally, dispatch a fresh API call with minimal parameters
  // to ensure we get a clean slate
  dispatch(
    filterRawMaterials({
      page: 1,
      page_size: 20,
      only_gcms: true,
    })
  );
};

export const clearSearchFilters = () => (dispatch, getState) => {
  // Get current only_mine value
  const { only_mine } = getState().filterSearch;

  // First, dispatch the complete clear action
  dispatch({
    type: CLEAR_SEARCH_FILTERS,
    preserveFilters: false,
  });

  // Then, explicitly clear concentration filters
  dispatch({ type: CLEAR_CONCENTRATION_FILTERS });

  // Finally, dispatch a fresh API call with minimal parameters
  // to ensure we get a clean slate
  dispatch(
    filterRawMaterials({
      page: 1,
      page_size: 20,
      only_gcms: true,
      only_mine, // Keep the tab state
    })
  );
};
export const MODAL_GET_RAW_SUPPLIER_REQUEST = "filterSearch.MODAL_GET_RAW_SUPPLIER_REQUEST";
export const MODAL_GET_RAW_SUPPLIER_SUCCESS = "filterSearch.MODAL_GET_RAW_SUPPLIER_SUCCESS";
export const MODAL_GET_RAW_SUPPLIER_FAILURE = "filterSearch.MODAL_GET_RAW_SUPPLIER_FAILURE";

export const MODAL_GET_RAW_PLANTPART_REQUEST = "filterSearch.MODAL_GET_RAW_PLANTPART_REQUEST";
export const MODAL_GET_RAW_PLANTPART_SUCCESS = "filterSearch.MODAL_GET_RAW_PLANTPART_SUCCESS";
export const MODAL_GET_RAW_PLANTPART_FAILURE = "filterSearch.MODAL_GET_RAW_PLANTPART_FAILURE";

export const MODAL_GET_RAW_PLANTNOTE_REQUEST = "filterSearch.MODAL_GET_RAW_PLANTNOTE_REQUEST";
export const MODAL_GET_RAW_PLANTNOTE_SUCCESS = "filterSearch.MODAL_GET_RAW_PLANTNOTE_SUCCESS";
export const MODAL_GET_RAW_PLANTNOTE_FAILURE = "filterSearch.MODAL_GET_RAW_PLANTNOTE_FAILURE";

export const MODAL_GET_RAW_PLANTFAMILY_REQUEST = "filterSearch.MODAL_GET_RAW_PLANTFAMILY_REQUEST";
export const MODAL_GET_RAW_PLANTFAMILY_SUCCESS = "filterSearch.MODAL_GET_RAW_PLANTFAMILY_SUCCESS";
export const MODAL_GET_RAW_PLANTFAMILY_FAILURE = "filterSearch.MODAL_GET_RAW_PLANTFAMILY_FAILURE";

export const MODAL_GET_RAW_PLANTCHAKRA_REQUEST = "filterSearch.MODAL_GET_RAW_PLANTCHAKRA_REQUEST";
export const MODAL_GET_RAW_PLANTCHAKRA_SUCCESS = "filterSearch.MODAL_GET_RAW_PLANTCHAKRA_SUCCESS";
export const MODAL_GET_RAW_PLANTCHAKRA_FAILURE = "filterSearch.MODAL_GET_RAW_PLANTCHAKRA_FAILURE";

export const MODAL_GET_RAW_INCINAME_REQUEST = "filterSearch.MODAL_GET_RAW_INCINAME_REQUEST";
export const MODAL_GET_RAW_INCINAME_SUCCESS = "filterSearch.MODAL_GET_RAW_INCINAME_SUCCESS";
export const MODAL_GET_RAW_INCINAME_FAILURE = "filterSearch.MODAL_GET_RAW_INCINAME_FAILURE";

export const MODAL_GET_RAW_CATEGORY_REQUEST = "filterSearch.MODAL_GET_RAW_CATEGORY_REQUEST";
export const MODAL_GET_RAW_CATEGORY_SUCCESS = "filterSearch.MODAL_GET_RAW_CATEGORY_SUCCESS";
export const MODAL_GET_RAW_CATEGORY_FAILURE = "filterSearch.MODAL_GET_RAW_CATEGORY_FAILURE";

export const MODAL_GET_CONSTITUENT_FAMILY_REQUEST = "filterSearch.MODAL_GET_CONSTITUENT_FAMILY_REQUEST";
export const MODAL_GET_CONSTITUENT_FAMILY_SUCCESS = "filterSearch.MODAL_GET_CONSTITUENT_FAMILY_SUCCESS";
export const MODAL_GET_CONSTITUENT_FAMILY_FAILURE = "filterSearch.MODAL_GET_CONSTITUENT_FAMILY_FAILURE";

export const MODAL_GET_ORGANIZED_CONSTITUENTS_REQUEST = "filterSearch.MODAL_GET_ORGANIZED_CONSTITUENTS_REQUEST";
export const MODAL_GET_ORGANIZED_CONSTITUENTS_SUCCESS = "filterSearch.MODAL_GET_ORGANIZED_CONSTITUENTS_SUCCESS";
export const MODAL_GET_ORGANIZED_CONSTITUENTS_FAILURE = "filterSearch.MODAL_GET_ORGANIZED_CONSTITUENTS_FAILURE";

export const MODAL_GET_RAW_WARNING_REQUEST = "filterSearch.MODAL_GET_RAW_WARNING_REQUEST";
export const MODAL_GET_RAW_WARNING_SUCCESS = "filterSearch.MODAL_GET_RAW_WARNING_SUCCESS";
export const MODAL_GET_RAW_WARNING_FAILURE = "filterSearch.MODAL_GET_RAW_WARNING_FAILURE";

export const MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST = "filterSearch.MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST";
export const MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS = "filterSearch.MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS";
export const MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE = "filterSearch.MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE";

export const MODAL_GET_THERAPEUTIC_USE_REQUEST = "filterSearch.MODAL_GET_THERAPEUTIC_USE_REQUEST";
export const MODAL_GET_THERAPEUTIC_USE_SUCCESS = "filterSearch.MODAL_GET_THERAPEUTIC_USE_SUCCESS";
export const MODAL_GET_THERAPEUTIC_USE_FAILURE = "filterSearch.MODAL_GET_THERAPEUTIC_USE_FAILURE";
export const UPDATE_COUNT = "filterSearch.UPDATE_COUNT";

export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (
      obj[prop] === true ||
      obj[prop] === false || // Also keep boolean false
      (obj[prop] && !_.isEmpty(obj[prop]) && obj[prop] != [""])
    ) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const setOnlyMine = (only_mine) => (dispatch, getState) => {
  dispatch({ type: SET_ONLY_MINE, only_mine });
};

export const setOrdering = (ordering) => (dispatch, getState) => {
  dispatch({ type: SET_ORDERING, ordering });
};

// New composite action in filterSearch.js
export const switchTab = (isMyOils) => (dispatch) => {
  // 1. Clear filters state in Redux
  dispatch({ type: CLEAR_SEARCH_FILTERS });
  dispatch({ type: CLEAR_CONCENTRATION_FILTERS });

  // 2. Update the only_mine flag
  dispatch({ type: SET_ONLY_MINE, only_mine: isMyOils });

  // 3. Make a single API call with the right parameters
  return dispatch(
    filterRawMaterials({
      page: 1,
      page_size: 20,
      only_gcms: true,
      // Explicitly use the passed value to avoid race conditions
      only_mine: isMyOils,
    })
  );
};

// In filterSearch.js
// Modify the filterRawMaterials function to ensure only_mine is properly handled
export const filterRawMaterials =
  (filters = {}, url = null, search) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_MATERIALS_REQUEST, filters });

    const { token } = dispatch(getToken());
    const { only_mine } = getState().filterSearch;
    const only_gcms = true;

    // Flatten nested filters and handle arrays
    const flattenFilters = (obj) => {
      const result = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (key === "filters" && typeof value === "object" && !Array.isArray(value)) {
          // Flatten nested filters object into top level
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            result[nestedKey] = Array.isArray(nestedValue) ? nestedValue.join(",") : nestedValue;
          });
        } else {
          // Handle top-level keys, converting arrays to strings
          result[key] = Array.isArray(value) ? value.join(",") : value;
        }
      });
      return result;
    };

    // Flatten the filters first
    const flattenedFilters = flattenFilters(filters);

    // Apply removeFalsy to clean up the flattened object
    const cleanedFilters = removeFalsy(flattenedFilters);

    // Make sure these values are preserved

    // Remove only_mine from cleanedFilters if it exists to prevent conflicts
    // with the value from Redux state
    if (cleanedFilters.hasOwnProperty("only_mine")) {
      delete cleanedFilters.only_mine;
    }
    const only_biz = !only_mine;

    const requested_ordering = filters.ordering ? String(filters.ordering) : undefined;
    let { ordering } = getState().filterSearch;
    if (requested_ordering) ordering = requested_ordering;

    // Add explicit logging for the only_mine value to debug
    // Build the endpoint URL with all parameters
    const endpoint =
      url ||
      `/pands/raw-materials/?${qs.stringify(
        {
          ...cleanedFilters,
          ordering,
          only_mine,
          only_biz,
          only_gcms,
        },
        { arrayFormat: "comma" }
      )}`;

    // Log the final endpoint for debugging

    return fetch(endpoint, {
      method: "GET",
      token,
      success: (res) => {
        const ingredients = res.results;
        const next = res.next;
        const count = res.count;
        dispatch({ type: MODAL_GET_RAW_MATERIALS_SUCCESS, ingredients, next });
        dispatch({ type: UPDATE_COUNT, count });
      },
      failure: (err) => {
        console.error("filterRawMaterials: Fetch error:", err);
        dispatch({ type: MODAL_GET_RAW_MATERIALS_FAILURE });
      },
    });
  };

export const getRawMaterialNames =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { only_mine } = getState().rawMaterials;
    dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_REQUEST });
    let qs = "?only_mine=" + only_mine;
    return fetch(`/pands/rawmaterialnames${qs}`, {
      token,
      method: "GET",
      success: (res) => {
        const ingredients = res.details;
        dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS, ingredients });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_FAILURE });
      },
    });
  };

export const getCAS =
  (params) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const only_biz = params.only_biz ? params.only_biz : false;
    dispatch({ type: MODAL_GET_CAS_REQUEST });
    let qs = "?only_biz=" + only_biz;
    return fetch(`/pands/cas${qs}`, {
      token,
      method: "GET",
      success: (res) => {
        const cas = res; // Looks like we don't need .results anymore since pagination is disabled
        dispatch({ type: MODAL_GET_CAS_SUCCESS, cas });
      },
      failure: (err) => {
        dispatch({ type: MODAL_GET_CAS_FAILURE });
      },
    });
  };

export const _getCAS =
  (params) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const only_biz = params.only_biz ? params.only_biz : false;
    dispatch({ type: MODAL_GET_CAS_REQUEST });
    let qs = "?only_biz=" + only_biz;
    return fetch(`/pands/cas${qs}`, {
      token,
      method: "GET",
      success: (res) => {
        const cas = res.results;
        // Add logging without changing original functionality
        const alphaPinene = cas?.find((item) => item.name?.toLowerCase() === "alpha-pinene".toLowerCase());

        dispatch({ type: MODAL_GET_CAS_SUCCESS, cas });
      },
      failure: (err) => {
        dispatch({ type: MODAL_GET_CAS_FAILURE });
      },
    });
  };

export const getSuppliers =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_SUPPLIER_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch(
      `/pands/suppliers/?${qs.stringify({
        search,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const supplier = res.results;
          const count = res.results.length;
          dispatch({ type: MODAL_GET_RAW_SUPPLIER_SUCCESS, supplier, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: MODAL_GET_RAW_SUPPLIER_FAILURE });
        },
      }
    );
  };

export const getPlantParts =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTPART_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantparts/", {
      method: "GET",
      token,
      success: (res) => {
        const plantpart = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_PLANTPART_SUCCESS, plantpart, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTPART_FAILURE });
      },
    });
  };

export const getPlantFamilys =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTFAMILY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantfamilys/", {
      method: "GET",
      token,
      success: (res) => {
        const plantfamily = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_RAW_PLANTFAMILY_SUCCESS,
          plantfamily,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTFAMILY_FAILURE });
      },
    });
  };

export const getPlantNotes =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTNOTE_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantnotes/", {
      method: "GET",
      token,
      success: (res) => {
        const plantnote = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_PLANTNOTE_SUCCESS, plantnote, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTNOTE_FAILURE });
      },
    });
  };

export const getPlantChakras =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTCHAKRA_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantchakras/", {
      method: "GET",
      token,
      success: (res) => {
        const plantchakra = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_RAW_PLANTCHAKRA_SUCCESS,
          plantchakra,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTCHAKRA_FAILURE });
      },
    });
  };

export const getCategories =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_CATEGORY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    const { only_mine } = getState().rawMaterials;
    const only_gcms = true;
    const endpoint = `/pands/raw-materials/categories/?${qs.stringify({
      only_mine,
      only_gcms,
    })}`;

    return fetch(endpoint, {
      method: "GET",
      token,
      success: (res) => {
        const category = res;
        const count = res.length;

        dispatch({ type: MODAL_GET_RAW_CATEGORY_SUCCESS, category, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_CATEGORY_FAILURE });
      },
    });
  };

export const getConstituentFamilies =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_CONSTITUENT_FAMILY_REQUEST });
    const { token } = dispatch(getToken());
    const endpoint = `/pands/constituentfamilies/`;

    return fetch(endpoint, {
      method: "GET",
      token,
      success: (res) => {
        const constituentFamilies = res.details;
        const count = res.length;

        dispatch({ type: MODAL_GET_CONSTITUENT_FAMILY_SUCCESS, constituentFamilies, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_CONSTITUENT_FAMILY_FAILURE });
      },
    });
  };

export const getConstituents =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_ORGANIZED_CONSTITUENTS_REQUEST });
    const { token } = dispatch(getToken());
    const endpoint = `/pands/getconstituents/`;

    return fetch(endpoint, {
      method: "GET",
      token,
      success: (res) => {
        const organizedConstituents = res.details;
        const count = res.length;

        dispatch({ type: MODAL_GET_ORGANIZED_CONSTITUENTS_SUCCESS, organizedConstituents, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_ORGANIZED_CONSTITUENTS_FAILURE });
      },
    });
  };

export const getWarnings =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_WARNING_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    return fetch("/pands/warnings/", {
      method: "GET",
      token,
      success: (res) => {
        const warning = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_WARNING_SUCCESS, warning, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_WARNING_FAILURE });
      },
    });
  };

export const getTherapeuticProperties =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/therapeuticproperties/", {
      method: "GET",
      token,
      success: (res) => {
        const therapeuticproperty = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS,
          therapeuticproperty,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE });
      },
    });
  };

export const getTherapeuticUses =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_THERAPEUTIC_USE_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/therapeuticuses/", {
      method: "GET",
      token,
      success: (res) => {
        const therapeuticuse = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_THERAPEUTIC_USE_SUCCESS,
          therapeuticuse,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_THERAPEUTIC_USE_FAILURE });
      },
    });
  };

export const openRawMaterialsModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_RAW_MATERIALS_MODAL });
};

export const closeRawMaterialsModal = () => (dispatch, getState) => {
  const { selectedCategory, only_mine } = getState().rawMaterials;
  dispatch(getRawMaterialsForSelectedCategory(selectedCategory, only_mine));
  dispatch({ type: CLOSE_RAW_MATERIALS_MODAL });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  only_mine: false,
  loading: false,
  modalRawMaterials: [],
  modalRawMaterialsModal: [],
  modalRawMaterialsModalOpened: false,

  modalRawMaterialNames: {},
  modalRawMaterialNamesLoad: false,
  totalModalRawMaterialNames: 0,

  ModalCAS: [],
  ModalCASLoad: false,
  totalModalCAS: 0,

  modalsuppliers: [],
  modalsuppliersLoad: false,
  totalModalsuppliers: 0,

  modalplantparts: [],
  modalplantpartsLoad: false,
  totalModalplantparts: 0,

  modalplantfamilys: [],
  modalplantfamilysLoad: false,
  totalModalplantfamilys: 0,

  modalplantnotes: [],
  modalplantnotesLoad: false,
  totalModalplantnotes: 0,

  modalplantchakras: [],
  modalplantchakrasLoad: false,
  totalModalplantchakras: 0,

  modalcategorys: [],
  modalcategorysLoad: false,
  totalModalcategorys: 0,

  constituentFamilies: [],
  constituentFamiliesLoad: false,
  totalConstituentFamilies: 0,

  organizedConstituents: [],
  organizedConstituentsLoad: false,
  totalOrganizedConstituents: 0,

  next: null,

  modalwarnings: [],
  modalwarningsLoad: false,
  totalModalwarnings: 0,

  modaltherapeuticproperties: [],
  modaltherapeuticpropertiesLoad: false,
  totalModaltherapeuticproperties: 0,

  modaltherapeuticuses: [],
  modaltherapeuticusesLoad: false,
  totalModaltherapeuticuses: 0,

  ordering: undefined,

  family_min_qty: undefined,
  functional_group_min_qty: undefined,
  constituent_min_qty: undefined,
};

export default createReducer(initialState, {
  [MODAL_GET_RAW_MATERIALS_SUCCESS]: (state, { ingredients, next }) => {
    return {
      ...state,
      next,
      modalRawMaterials: ingredients,
      loading: false,
      recipesLoad: false,
    };
  },
  [MODAL_GET_RAW_MATERIAL_NAMES_REQUEST]: (state, action) => ({
    modalRawMaterialNamesLoad: true,
  }),
  [MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS]: (state, { ingredients }) => ({
    modalRawMaterialNamesLoad: false,
    modalRawMaterialNames: ingredients,
  }),
  [MODAL_GET_RAW_MATERIAL_NAMES_FAILURE]: (state, action) => ({
    modalRawMaterialNamesLoad: false,
    loading: false,
  }),

  [MODAL_GET_RAW_MATERIALS_REQUEST]: (state, action) => ({
    modalRawMaterialNamesLoad: false,
  }),

  [MODAL_GET_CAS_REQUEST]: (state, action) => ({
    modalCASLoad: true,
  }),
  [MODAL_GET_CAS_SUCCESS]: (state, { cas }) => ({
    modalCASLoad: false,
    modalCAS: cas,
  }),
  [MODAL_GET_CAS_FAILURE]: (state, action) => ({
    modalCASLoad: false,
  }),
  [CLEAR_RAW_MATERIALS_MODAL]: (state, {}) => ({
    only_mine: false,
    modalRawMaterials: [],
    modalRawMaterialsModal: [],
    modalRawMaterialsModalOpened: false,

    modalRawMaterialNames: {},
    modalRawMaterialNamesLoad: false,
    totalModalRawMaterialNames: 0,

    ModalCAS: [],
    ModalCASLoad: false,
    totalModalCAS: 0,

    modalsuppliers: [],
    modalsuppliersLoad: false,
    totalModalsuppliers: 0,

    modalplantparts: [],
    modalplantpartsLoad: false,
    totalModalplantparts: 0,

    modalplantfamilys: [],
    modalplantfamilysLoad: false,
    totalModalplantfamilys: 0,

    modalplantnotes: [],
    modalplantnotesLoad: false,
    totalModalplantnotes: 0,

    modalplantchakras: [],
    modalplantchakrasLoad: false,
    totalModalplantchakras: 0,

    modalcategorys: [],
    modalcategorysLoad: false,
    totalModalcategorys: 0,

    constituentFamilies: [],
    constituentFamiliesLoad: false,
    totalConstituentFamilies: 0,

    organizedConstituents: [],
    organizedConstituentsLoad: false,
    totalOrganizedConstituents: 0,

    next: null,

    modalwarnings: [],
    modalwarningsLoad: false,
    totalModalwarnings: 0,

    modaltherapeuticproperties: [],
    modaltherapeuticpropertiesLoad: false,
    totalModaltherapeuticproperties: 0,

    modaltherapeuticuses: [],
    modaltherapeuticusesLoad: false,
    totalModaltherapeuticuses: 0,

    ordering: undefined,
  }),
  [OPEN_RAW_MATERIALS_MODAL]: (state, action) => ({
    modalRawMaterialsModalOpened: true,
  }),
  [CLOSE_RAW_MATERIALS_MODAL]: (state, action) => ({
    modalRawMaterialsModalOpened: false,
    selectedRows: [],
    modalRawMaterials: [],
    modalRawMaterialNames: {},
    search: undefined,
  }),
  [MODAL_GET_RAW_SUPPLIER_REQUEST]: (state, action) => ({
    modalsuppliersLoad: true,
  }),
  [MODAL_GET_RAW_SUPPLIER_SUCCESS]: (state, action) => ({
    modalsuppliersLoad: false,
    modalsuppliers: action.supplier,
    totalModalsuppliers: action.count,
  }),
  [MODAL_GET_RAW_SUPPLIER_FAILURE]: (state, action) => ({
    modalsuppliersLoad: false,
  }),

  [MODAL_GET_RAW_PLANTPART_REQUEST]: (state, action) => ({
    modalplantpartsLoad: true,
  }),
  [MODAL_GET_RAW_PLANTPART_SUCCESS]: (state, action) => ({
    modalplantpartsLoad: false,
    modalplantparts: action.plantpart,
    totalModalplantparts: action.count,
  }),
  [MODAL_GET_RAW_PLANTPART_FAILURE]: (state, action) => ({
    modalplantpartsLoad: false,
  }),

  [MODAL_GET_RAW_PLANTFAMILY_REQUEST]: (state, action) => ({
    modalplantfamilysLoad: true,
  }),
  [MODAL_GET_RAW_PLANTFAMILY_SUCCESS]: (state, action) => ({
    modalplantfamilysLoad: false,
    modalplantfamilys: action.plantfamily,
    totalModalplantfamilys: action.count,
  }),
  [MODAL_GET_RAW_PLANTFAMILY_FAILURE]: (state, action) => ({
    modalplantfamilysLoad: false,
  }),

  [MODAL_GET_RAW_PLANTNOTE_REQUEST]: (state, action) => ({
    modalplantnotesLoad: true,
  }),
  [MODAL_GET_RAW_PLANTNOTE_SUCCESS]: (state, action) => ({
    modalplantnotesLoad: false,
    modalplantnotes: action.plantnote,
    totalModalplantnotes: action.count,
  }),
  [MODAL_GET_RAW_PLANTNOTE_FAILURE]: (state, action) => ({
    modalplantnotesLoad: false,
  }),

  [MODAL_GET_RAW_PLANTCHAKRA_REQUEST]: (state, action) => ({
    modalplantchakrassLoad: true,
  }),
  [MODAL_GET_RAW_PLANTCHAKRA_SUCCESS]: (state, action) => ({
    modalplantchakrasLoad: false,
    modalplantchakras: action.plantchakra,
    totalModalplantchakras: action.count,
  }),
  [MODAL_GET_RAW_PLANTCHAKRA_FAILURE]: (state, action) => ({
    modalplantchakrasLoad: false,
  }),

  [MODAL_GET_RAW_CATEGORY_REQUEST]: (state, action) => ({
    modalcategorysLoad: true,
  }),
  [MODAL_GET_RAW_CATEGORY_SUCCESS]: (state, action) => ({
    modalcategorysLoad: false,
    modalcategorys: action.category,
    totalModalcategorys: action.count,
  }),
  [MODAL_GET_RAW_CATEGORY_FAILURE]: (state, action) => ({
    modalcategorysLoad: false,
  }),

  [MODAL_GET_CONSTITUENT_FAMILY_REQUEST]: (state, action) => ({
    constituentFamiliesLoad: true,
  }),
  [MODAL_GET_CONSTITUENT_FAMILY_SUCCESS]: (state, action) => ({
    constituentFamiliesLoad: false,
    constituentFamilies: action.constituentFamilies,
    totalConstituentFamilies: action.count,
  }),
  [MODAL_GET_CONSTITUENT_FAMILY_FAILURE]: (state, action) => ({
    constituentFamiliesLoad: false,
  }),

  [MODAL_GET_ORGANIZED_CONSTITUENTS_REQUEST]: (state, action) => ({
    organizedConstituentsLoad: true,
  }),
  [MODAL_GET_ORGANIZED_CONSTITUENTS_SUCCESS]: (state, action) => ({
    organizedConstituentsLoad: false,
    organizedConstituents: action.organizedConstituents,
    totalOrganizedConstituents: action.count,
  }),
  [MODAL_GET_ORGANIZED_CONSTITUENTS_FAILURE]: (state, action) => ({
    organizedConstituentsLoad: false,
  }),

  [MODAL_GET_RAW_WARNING_REQUEST]: (state, action) => ({
    modalwarningsLoad: true,
  }),
  [MODAL_GET_RAW_WARNING_SUCCESS]: (state, action) => ({
    modalwarningsLoad: false,
    modalwarnings: action.warning,
    totalModalwarnings: action.count,
  }),
  [MODAL_GET_RAW_WARNING_FAILURE]: (state, action) => ({
    modalwarningsLoad: false,
  }),

  [MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST]: (state, action) => ({
    modaltherapeuticpropertiesLoad: true,
  }),
  [MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS]: (state, action) => ({
    modaltherapeuticpropertiesLoad: false,
    modaltherapeuticproperties: action.therapeuticproperty,
    totalModaltherapeuticproperties: action.count,
  }),
  [MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE]: (state, action) => ({
    modaltherapeuticpropertiesLoad: false,
  }),

  [MODAL_GET_THERAPEUTIC_USE_REQUEST]: (state, action) => ({
    modaltherapeuticusesLoad: true,
  }),
  [MODAL_GET_THERAPEUTIC_USE_SUCCESS]: (state, action) => ({
    modaltherapeuticusesLoad: false,
    modaltherapeuticuses: action.therapeuticuse,
    totalModaltherapeuticuses: action.count,
  }),
  [MODAL_GET_THERAPEUTIC_USE_FAILURE]: (state, action) => ({
    modaltherapeuticusesLoad: false,
  }),
  [UPDATE_COUNT]: (state, action) => ({ count: action.count }),
  [SET_ORDERING]: (state, { ordering }) => ({
    ordering,
  }),
  [SET_ONLY_MINE]: (state, action) => {
    return {
      ...state,
      only_mine: action.only_mine,
    };
  },

  [CLEAR_SEARCH_FILTERS]: (state, action) => {
    if (action.preserveFilters) {
      // Only clear search results and pagination, but keep filter state
      return {
        ...state,
        modalRawMaterials: [],
        next: null,
        count: 0,
      };
    } else {
      // Complete reset of all filter state
      return {
        ...state,
        // Reset data and pagination
        modalRawMaterials: [],
        next: null,
        count: 0,

        // Reset all filter related fields
        ordering: undefined,

        // Reset concentration filters
        family_min_qty: undefined,
        functional_group_min_qty: undefined,
        constituent_min_qty: undefined,

        // Reset all the filter flags that are passed to the API
        // These match the API parameter names from your code
        search: undefined,
        raw_ingredient__part: undefined,
        raw_ingredient__family: undefined,
        raw_ingredient__note: undefined,
        raw_ingredient__chakra: undefined,
        raw_ingredient__category: undefined,
        raw_ingredient__therapeutic__therapeutic_property: undefined,
        raw_ingredient__therapeutic__therapeutic_use: undefined,
        supplier: undefined,
        cat: undefined,
        constituentqty__cas__family: undefined,
        constituentqty__cas__id: undefined,
        chemical_family: undefined,
        functional_group: undefined,
        chemical_constituent: undefined,
        favorite: undefined,

        // Keep loading state as false
        loading: false,

        // Preserve only_mine as it's a tab state, not a filter
        // only_mine: state.only_mine,
      };
    }
  },
  [CLEAR_CONCENTRATION_FILTERS]: (state) => ({
    ...state,
    family_min_qty: undefined,
    functional_group_min_qty: undefined,
    constituent_min_qty: undefined,
  }),
});
